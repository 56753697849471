#root {
  background-color: #fafafa;
  background: linear-gradient(90deg, #fcfcfc, #e8e8e8);
}
table {
  background-color: #fdfdff;
}
table > thead,
.card-header {
  background-color: #eeeeee;
  background: linear-gradient(#fdfeff, #e8e9ea);
}

#nav-bar-container {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2);
  background-color: #f8f9fa;
  background: linear-gradient(#fdfeff, #d8d9da);
}

.loading-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#alert-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9998;
}
.alert {
  margin: 1rem;
}

.filter-body {
  position: relative;
}
.clear-filter {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
}
.clear-filter-btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.collapse-toggler {
  font-size: 1.5rem;
  -webkit-transition: 150ms;
  transition: 150ms;
}
.collapse-toggler.collapsed {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.bg-custom1 {
  background-color: #8e24aa;
}
.btn-custom1 {
  color: #fff;
  background-color: #8e24aa;
  border-color: #8e24aa;
}
.btn-custom1:hover,
.btn-custom1:not(:disabled):not(.disabled).active,
.btn-custom1:not(:disabled):not(.disabled):active,
.show > .btn-custom1.dropdown-toggle {
  color: #fff;
  background-color: #7e149a;
  border-color: #7e149a;
}
.text-custom1 {
  color: #8e24aa;
}
a.text-custom1:focus,
a.text-custom1:hover {
  color: #7e149a;
}
.list-group-item-custom1 {
  color: #4e006a;
  background-color: #ce94ff;
}

.bg-custom2 {
  background-color: #ae5000;
}
.btn-custom2 {
  color: #fff;
  background-color: #ae5000;
  border-color: #ae5000;
}
.btn-custom2:hover,
.btn-custom2:not(:disabled):not(.disabled).active,
.btn-custom2:not(:disabled):not(.disabled):active,
.show > .btn-custom2.dropdown-toggle {
  color: #fff;
  background-color: #9e4000;
  border-color: #9e4000;
}
.text-custom2 {
  color: #ae5000;
}
a.text-custom2:focus,
a.text-custom2:hover {
  color: #9e4000;
}
.list-group-item-custom2 {
  color: #4e0000;
  background-color: #dea060;
}

/* lib style overriding */
.react-datepicker__day--today {
  text-decoration: underline;
}
.react-datepicker__day--outside-month {
  color: #c0c0c0;
}
.react-datepicker.react-datepicker--time-only .react-datepicker__time-container {
  width: inherit;
  min-width: 85px;
}
.react-datepicker.react-datepicker--time-only
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  width: inherit;
  min-width: 85px;
}

.form-control {
  padding: 0.375rem 0.375rem;
}
input.datepicker.form-control[readonly] {
  background-color: #fff;
}
input.datepicker.form-control[readonly][disabled] {
  background-color: #e9ecef;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #999999;
}